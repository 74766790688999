import * as React from "react"
import Layout from "../components/layout";
import AppStore from "../components/appstore";
import {useEffect, useState} from "react";

// markup
const DrawPage = ({location}) => {

  const [shareState, setShareState] = useState({
    href: '',
    short: ''
  })

  useEffect(() => {
    setShareState({
      href: 'https://app.scribblex.com' + location.pathname + location.search,
      short: 'scribblex.com' + location.pathname + location.search
    })
  }, [setShareState]);

  return (
    <Layout title="Let's draw!">
      <div className="container pt-12 pb-20 max-w-2xl text-center">
        <h1>Let's draw something!</h1>
        <p className="text-lg sm:text-2xl mt-4">
          Someone wants to draw with you in ScribbleX.
        </p>
        <p className="text-lg sm:text-2xl">
          To continue the drawing...
        </p>
        <h2 className="mt-8 mb-4">1. Download the app</h2>
        <div className="flex justify-center">
          <AppStore/>
        </div>
        <h2 className="mt-8 mb-4">2. Click on the link again</h2>
        <div className="bg-white border-primary border-solid border-2 py-4 rounded-2xl ">
          <a className="text-lg sm:text-2xl m-2" suppressHydrationWarning href={shareState.href}>
            {shareState.short}
          </a>
        </div>
      </div>
    </Layout>
  )
}

export default DrawPage
